const func = (...data) => {
  return {
    "flat": data[0].flat,
    "floor": data[0].floor,
    "cost": 0,
    "status": data[0].status ? data[0].status : "Свободно",
    "costM2": 0,
    "amountRooms": data[0].amountRooms ? data[0].amountRooms : 2,
    "type": data[0].type,
    "typePlacement": "Жилая недвижимость",
    "totalArea": data[0].totalArea,
    "kitchenArea": data[0].kitchenArea ? data[0].kitchenArea : 0,
    "finishing": "Черновая",
    "view": "Во двор",
    "bathroomAmount": 1,
    "balconyAmount": 1,
    "index": data[0].index
  }
}

export const data = {
  data: [
      func({floor: 1, flat: 1, totalArea: 38.10, amountRooms: 3, type: "E", index: 1, status: "Бронь"}),
      func({floor: 1, flat: 2, totalArea: 49.00, amountRooms: 3, type: "F", index: 2, status: "Бронь"}),
      func({floor: 1, flat: 3, totalArea: 150.39, amountRooms: 3, type: "G", index: 3, status: "Бронь"}),
      // 2  
      func({floor: 2, flat: 1, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 4, type: "C"}),
      func({floor: 2, flat: 2, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 5, type: "A"}),
      func({floor: 2, flat: 3, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 6, type: "B"}),
      func({floor: 2, flat: 4, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 7, type: "D"}),
      // 3
      func({floor: 3, flat: 5, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 8, type: "C"}),
      func({floor: 3, flat: 6, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 9, type: "A"}),
      func({floor: 3, flat: 7, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 10, type: "B"}),
      func({floor: 3, flat: 8, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 11, type: "D"}),
      // 4  
      func({floor: 4, flat: 9, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 12, type: "C"}),
      func({floor: 4, flat: 10, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 13, type: "A"}),
      func({floor: 4, flat: 11, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 14, type: "B"}),
      func({floor: 4, flat: 12, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 15, type: "D"}),
      // 5  
      func({floor: 5, flat: 13, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 16, type: "C"}),
      func({floor: 5, flat: 14, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 17, type: "A"}),
      func({floor: 5, flat: 15, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 18, type: "B"}),
      func({floor: 5, flat: 16, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 19, type: "D"}),
      // 6  
      func({floor: 6, flat: 17, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 20, type: "C"}),
      func({floor: 6, flat: 18, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 21, type: "A"}),
      func({floor: 6, flat: 19, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 22, type: "B"}),
      func({floor: 6, flat: 20, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 23, type: "D"}),
      // 7  
      func({floor: 7, flat: 21, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 24, type: "C"}),
      func({floor: 7, flat: 22, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 25, type: "A"}),
      func({floor: 7, flat: 23, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 26, type: "B"}),
      func({floor: 7, flat: 24, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 27, type: "D"}),
      // 8  
      func({floor: 8, flat: 25, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 28, type: "C"}),
      func({floor: 8, flat: 26, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 29, type: "A"}),
      func({floor: 8, flat: 27, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 30, type: "B"}),
      func({floor: 8, flat: 28, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 31, type: "D"}),
      // 9 
      func({floor: 9, flat: 29, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 32, type: "C"}),
      func({floor: 9, flat: 30, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 33, type: "A"}),
      func({floor: 9, flat: 31, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 34, type: "B"}),
      func({floor: 9, flat: 32, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 35, type: "D"}),
      // 10  
      func({floor: 10, flat: 33, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 36, type: "C"}),
      func({floor: 10, flat: 34, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 37, type: "A"}),
      func({floor: 10, flat: 35, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 38, type: "B"}),
      func({floor: 10, flat: 36, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 39, type: "D"}),
      // 11  
      func({floor: 11, flat: 37, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 40, type: "C"}),
      func({floor: 11, flat: 38, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 41, type: "A"}),
      func({floor: 11, flat: 39, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 42, type: "B"}),
      func({floor: 11, flat: 40, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 43, type: "D"}),
      // 12  
      func({floor: 12, flat: 41, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 44, type: "C"}),
      func({floor: 12, flat: 42, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 45, type: "A"}),
      func({floor: 12, flat: 43, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 46, type: "B"}),
      func({floor: 12, flat: 44, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 47, type: "D"}),
      // 13  
      func({floor: 13, flat: 45, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 48, type: "C"}),
      func({floor: 13, flat: 46, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 49, type: "A"}),
      func({floor: 13, flat: 47, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 50, type: "B"}),
      func({floor: 13, flat: 48, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 51, type: "D"}),
      // 14  
      func({floor: 14, flat: 49, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 52, type: "C"}),
      func({floor: 14, flat: 50, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 53, type: "A"}),
      func({floor: 14, flat: 51, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 54, type: "B"}),
      func({floor: 14, flat: 52, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 55, type: "D"}),
      // 15 
      func({floor: 15, flat: 53, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 56, type: "C"}),
      func({floor: 15, flat: 54, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 57, type: "A"}),
      func({floor: 15, flat: 55, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 58, type: "B"}),
      func({floor: 15, flat: 56, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 59, type: "D"}),
      // 16  
      func({floor: 16, flat: 57, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 60, type: "C"}),
      func({floor: 16, flat: 58, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 61, type: "A"}),
      func({floor: 16, flat: 59, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 62, type: "B"}),
      func({floor: 16, flat: 60, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 63, type: "D"}),
      // 17 
      func({floor: 17, flat: 61, amountRooms: 3, totalArea: 92.44, kitchenArea: 8.05, index: 64, type: "C", status: "Бронь"}),
      func({floor: 17, flat: 62, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 65, type: "A"}),
      func({floor: 17, flat: 63, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 66, type: "B"}),
      func({floor: 17, flat: 64, amountRooms: 3, totalArea: 91.62, kitchenArea: 7.24, index: 67, type: "D"}),
      // 18
      func({floor: 18, flat: 65, amountRooms: 2, totalArea: 92.44, kitchenArea: 8.05, index: 68, type: "C"}),
      func({floor: 18, flat: 66, amountRooms: 2, totalArea: 64.14, kitchenArea: 7.52, index: 69, type: "A"}),
      func({floor: 18, flat: 67, amountRooms: 3, totalArea: 64.14, kitchenArea: 7.52, index: 70, type: "B"}),
      func({floor: 18, flat: 68, amountRooms: 5, totalArea: 140.11, kitchenArea: 7.24, index: 71, type: "18A", status: "Бронь"})
  ]
}

