import React, { useEffect, useLayoutEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Form, Input, Checkbox, Button } from 'antd'

import Layouts from '../Layouts/Layouts'
import Interactive from '../Interactive/Interactive'
import ChessHeader from '../ChessHeader/ChessHeader'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import ModalWrapper from '../../wrapers/ModalWrarrer/ModalWrapper'
import ModalPolicy from '../../wrapers/ModalWrarrer/ModalPolicy'
import ModalSuccess from '../../wrapers/ModalWrarrer/ModalSuccess'
import { content } from '../../constants/langs'
import { data } from '../../data/data'
import { layoutsImgA, layoutsImgB, layoutsImgC, layoutsImgD, layoutsImg18A, layoutsImgE, layoutsImgF, layoutsImgG, } from '../../constants/plans'
import { getChess, sendData, changeMessageStatus } from '../../core/actions/restChessActions'

import style from './Chess.module.scss'

export default function Chess() {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const chess = useSelector(({restChessReducer: { chess }}) => chess)
  const flat = useSelector(({restChessReducer: { flat }}) => flat)
  const module = useSelector(({changeTextReducer: { module }}) => module)
  const lang = useSelector(({changeTextReducer: { lang }}) => lang)
  const text = useSelector(({changeTextReducer: { text }}) => text)
  const mobile = useSelector(({changeTextReducer: { mobile }}) => mobile)
  const successMessage = useSelector(({restChessReducer: { successMessage }}) => successMessage)

  const [card, setCard] = useState("")
  const [typeCard, setTypeCard] = useState("A")
  const [calcCard, setCalcCard] = useState(0)
  const [showCard, setShowCard] = useState(false)
  const [showPlane, setShowPlane] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showPolicy, setShowPolicy] = useState(false)
  const [showModalSuccess, setShowModalSuccess] = useState(false)

  useEffect(() => {
    dispatch(getChess())
  }, [])

  useEffect(() => {
    if (flat !== null){
      const filterFlat = chess?.filter(item => item.floor === flat.floor)?.find(elem => elem.type === flat.type)
      setCard(filterFlat)
      setShowCard(true)
      setTypeCard(filterFlat.type)
      setCalcCard(0)
    }
  }, [flat])
  
  useEffect(() => {
    successMessage ? setShowModalSuccess(true) : setShowModalSuccess(false)
    setTimeout(() => setShowModalSuccess(false), 2000)
    setTimeout(() => dispatch(changeMessageStatus()), 2000)
  }, [successMessage])

  chess?.sort((a, b) => b.index - a.index)

  const gen = []
  for (let i = 0; i < chess?.length; i=i+4) {
    if (i+4 < chess.length){
      gen.push(chess.slice(i, i+4))
    }
    if (i+4 > chess.length){
      gen.push(chess.slice(i))
    }
  }

  const onCardFlat = (data) => {
    setCard(data)
    setShowCard(true)
    setTypeCard(data.type)
    setCalcCard(0)
  }
  const onSubmit = (e) => {
    dispatch(sendData({...e, flat: card.flat, cost: card.cost}))
    form.resetFields()
    setShowModal(false)
  }
 
  const nextImg = () => {
    if (typeCard === "18A" && calcCard < 3) setCalcCard(calcCard +1)
    if (typeCard !== "18A") setCalcCard(1)
  }
  const prevImg = () => {
    if (typeCard === "18A" && calcCard > 0) setCalcCard(calcCard -1)
    if (typeCard !== "18A") setCalcCard(0)
  }
  const arrows = <>
    <img src={require('../Layouts/images/arrow_right.png')} style={{position: "absolute", 
      top: "40%", right: typeCard !== "C" || typeCard !== "D" || typeCard !== "18A" ? -3 : 55, cursor: "pointer"}} width="16" height="16" alt='search'
      onClick={nextImg}
    />
    <img src={require('../Layouts/images/arrow_left.png')} style={{position: "absolute", 
      top: "40%", left: 0, cursor: "pointer"}} width="16" height="16" alt='search'
      onClick={prevImg}
    />
  </>
  return (
    <div className={style.mainChess}>
      <ChessHeader/>
      <div className={style.wrapChess} style={{display: module === "Шахматка" ? "" : "none", position : showCard ? "fixed" : ""}}>
        <span className={style.title}>{content.chess.title[lang].title}</span>
        {
          gen.map((item, index) => {
            return (
              <div className={style.chessBlock}>
                <span className={style.numFloor} style={{fontSize: mobile && text ? 20 : !mobile && text ? 26 : !mobile && !text ? 20 : 16}}>{item[0]?.floor}</span>
                {
                  item.reverse().map(elem => {
                    return (
                      <div className={style.chess} 
                        style={{backgroundColor: elem.status === "Свободно" ?'darkgreen' : elem.status === "Бронь" ? 'brown' : elem.status === "Продано" ? '#403A3A' : elem.status === "Нет в продаже" ? 'darkgrey' : ""}}
                        onClick={() => onCardFlat(elem)}
                      >
                        <div className={style.wrapText}>
                          <span className={style.text} style={{fontSize: mobile && text ? 13 : !mobile && text ? 21 : !mobile && !text ? 16 : 11}}>{elem.amountRooms} {elem.type === "Торговое помещение" ? content.chess.cards[lang].premises : content.chess.cards[lang].rooms}</span>
                          <span className={style.text} style={{fontSize: mobile && text ? 13 : !mobile && text ? 21 : !mobile && !text ? 16 : 11}}>{content.chess.cards[lang].flat} № {elem.flat}</span>
                        </div>
                        <span className={style.text} style={{display: mobile ? "none" : "", fontSize: text ? 21 : 16}}>{content.chess.cards[lang].cost} - {elem.costM2} BYN/M2</span>
                        <div className={style.wrapText}>
                          <span className={style.text} style={{fontSize: mobile && text ? 13 : !mobile && text ? 21 : !mobile && !text ? 16 : 11}}>{content.chess.cards[lang].totalArea} - {elem.totalArea} m2</span>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
        
      </div>
      <div style={{display: module === "Планировки" ? "" : "none"}}>
        <Layouts/>
      </div>
      <div style={{display: module === "Интерактив" ? "" : "none"}}>
        <Interactive/>
      </div>
      <div className={style.wrapShowCard} style={{right: showCard ? 0 : -430}}>
        <div className={style.wrapTitle}>
          <span className={style.title} style={{fontSize: text ? 25 : 20}}>{card.type === "E" || card.type === "F" ||card.type === "G" ? content.chess.card[lang].retailSpace : content.chess.card[lang].flat} № {card.flat}</span>
          <img src={require('./images/close.png')} width="40" height="41" alt='plane' style={{marginRight: 30}}
            onClick={() =>setShowCard(false)}
          />
        </div>
        <span className={style.textCard} style={{fontSize: text ? 23 : 18}}>{content.chess.card[lang].floor} {card.floor}</span>
        <div className={style.wrapStatus} 
          style={{fontSize: text ? 23 : 18, backgroundColor: card.status === "Свободно" ?'darkgreen' : card.status === "Бронь" ? 'brown' : card.status === "Продано" ? '#403A3A' : card.status === "Нет в продаже" ? 'darkgrey' : ""}}
        >{card.status === "Свободно" ? content.сhessHeader[lang].free : card.status === "Бронь" ? content.сhessHeader[lang].reserv : card.status === "Продано" ? content.сhessHeader[lang].sold  : card.status === "Нет в продаже" ? content.сhessHeader[lang].notSale : ""}
          <img src={require('./images/search.png')} className={style.search} 
            style={{display : mobile ? "none" : "", position: "absolute", right: 24}} width="48" height="48" alt='search'
            onClick={() => setShowPlane(true)}
          />
        </div>
        
        <div style={{position: "relative", marginTop: 24}}>
          { typeCard === "A" ? layoutsImgA(calcCard) : typeCard === "B" ? layoutsImgB(calcCard) : typeCard === "C" ? layoutsImgC(calcCard) : typeCard === "D" ? layoutsImgD(calcCard) : typeCard === "18A" ? layoutsImg18A(calcCard) 
            : typeCard === "E" ? layoutsImgE(calcCard) : typeCard === "F" ? layoutsImgF(calcCard) : typeCard === "G" ? layoutsImgG(calcCard) : ""}
          {arrows}
        </div>

        <span className={style.textCard} style={{fontSize: text ? 23 : 18, marginTop: 0}}>{card.type === "E" || card.type === "F" ||card.type === "G" ? content.chess.card[lang].premises : content.chess.card[lang].rooms} - {card.amountRooms}</span>
        <span className={style.textCard} style={{fontSize: text ? 23 : 18}}>{content.chess.card[lang].totalArea} - {card.totalArea} m2</span>
        <span className={style.textCard} style={{fontSize: text ? 23 : 18}}>{content.chess.card[lang].cost} - {card.cost} бел.руб.</span>
        <span className={style.textCard} style={{fontSize: text ? 23 : 18}}>{content.chess.card[lang].costM2} m2 - {card.costM2} бел.руб/m2</span>
        <div className={style.wrapSubmitBtn}
          style={{fontSize: text ? 25 : 20}}
          onClick={() => setShowModal(true)}
        >{content.chess.card[lang].textBtn}</div>
        <span className={style.title} style={{fontSize: text ? 25 : 20}}>{content.chess.card[lang].charact}:</span>
        <div className={style.wrapCharacter}>
          <img src={require('./images/kitchen.png')} width="40" height="41" alt='plane'/>
          <span className={style.textCard} style={{paddingLeft: 10, fontSize: text ? 23 : 18}}>{content.chess.card[lang].kitchenArea} - {card.kitchenArea} m2</span>
        </div>
        <div className={style.wrapCharacter}>
          <img src={require('./images/view.jpg')} width="40" height="41" alt='plane'/>
          <span className={style.textCard} style={{paddingLeft: 10, fontSize: text ? 23 : 18}}>
            {content.chess.card[lang].view} - {card.view === "Во двор" ? content.chess.card[lang].viewTextYard : card.view === "На улицу" ? content.chess.card[lang].viewTextStreet : ""}</span>
        </div>
        <div className={style.wrapCharacter}>
          <img src={require('./images/finishing.jpg')} width="40" height="41" alt='plane'/>
          <span className={style.textCard} style={{paddingLeft: 10, fontSize: text ? 23 : 18}}>
            {content.chess.card[lang].finishing} - {card.finishing === "Черновая" ? content.chess.card[lang].finishingTextR : card.finishing === "Чистовая" ? content.chess.card[lang].finishingTextC : ""}</span>
        </div>
        <span className={style.title} style={{fontSize: text ? 25 : 20}}>{content.chess.card[lang].add}:</span>
        <span className={style.textCard} style={{fontSize: text ? 23 : 18}}>{content.chess.card[lang].balconyAmount} - {card.balconyAmount}</span>
        <span className={style.textCard} style={{fontSize: text ? 23 : 18}}>{content.chess.card[lang].bathroomAmount} - {card.bathroomAmount}</span>
      </div>
      <ModalWrapper showModal={showModal}>
        <Form
          onFinish={(e) => onSubmit(e)}
          initialValues={{comment: card.flat}}
          className={style.wrapBlocks}
          form={form}
        >
          <div className={style.wrapTitle}>
            <span className={style.titleModal} style={{fontSize: text ? 26 : 21}}>{content.chess.chessForm[lang].title}</span>
            <img src={require('./images/close.png')} width="40" height="41" alt='plane'
              onClick={() => setShowModal(false)}
            />
          </div>
          <span className={style.text} style={{fontSize: text ? 24 : 20}}>{content.chess.chessForm[lang].flat} № {card.flat} &nbsp;  {content.chess.chessForm[lang].quarter}</span>
          <span className={style.text} style={{fontSize: text ? 20 : 16, marginTop: 10}}>{content.chess.chessForm[lang].name}</span>
          <Form.Item name='name'>
            <Input className={style.input} style={{fontSize: text ? 22 : 18}} required={true} />
          </Form.Item>
          <span className={style.text} style={{fontSize: text ? 20 : 16}}>{content.chess.chessForm[lang].email} email</span>
          <Form.Item name='email'>
            <Input className={style.input} style={{fontSize: text ? 22 : 18}} required={true} />
          </Form.Item>
          <span className={style.text} style={{fontSize: text ? 20 : 16}}>{content.chess.chessForm[lang].phone}</span>
          <Form.Item name='phone'>
            <Input className={style.input} style={{fontSize: text ? 22 : 18}} required={true} defaultValue="+375"/>
          </Form.Item>
          <span className={style.text} style={{fontSize: text ? 20 : 16}}>{content.chess.chessForm[lang].comment}</span>
          <Form.Item name='comment'>
            <Input.TextArea defaultValue="" className={style.input} style={{height: 60, fontSize: text ? 22 : 18}}/>
          </Form.Item>
          <Form.Item name='checked' valuePropName="checked">
            <div>
              <Checkbox></Checkbox>
              <span className={style.check} style={{fontSize: text ? 18 : 14}}>{content.chess.chessForm[lang].bank}</span>
            </div>
            
          </Form.Item>
          <Form.Item>
            <Button 
              htmlType='submit'
              className={style.btn}
              style={{backgroundColor: "green", color: "white", fontSize: text ? 24 : 20}}
              >{content.chess.chessForm[lang].send}
            </Button>
          </Form.Item>
          <span className={style.text} style={{fontSize: text ? 18 : 14, color: "gray", textDecoration: "underline", cursor: "pointer"}}
            onClick={() => setShowPolicy(true)}
          >{content.chess.chessForm[lang].textBottom}</span>
        </Form>
      </ModalWrapper>
      <ModalWrapper showModal={showPlane}>
        <div style={{position: "relative"}}>
          <img src={require('./images/close.png')} style={{position: "absolute", right: typeCard === "A" || typeCard === "B" ? 0 : 50, top: -6 }} width="44" height="44" alt='close'
            onClick={() => setShowPlane(false)}
          />
          { typeCard === "A" ? layoutsImgA(calcCard) : typeCard === "B" ? layoutsImgB(calcCard) : typeCard === "C" ? layoutsImgC(calcCard) : typeCard === "D" ? layoutsImgD(calcCard) : typeCard === "18A" ? layoutsImg18A(calcCard) 
            : typeCard === "E" ? layoutsImgE(calcCard) : typeCard === "F" ? layoutsImgF(calcCard) : typeCard === "G" ? layoutsImgG(calcCard) : ""}
          {arrows}
        </div>
      </ModalWrapper>
      <ModalPolicy showModal={showPolicy}>
        <div className={style.wrapPolicy}>
          <img src={require('./images/close.png')} width="40" height="41" alt='plane' style={{marginRight: 20}}
            onClick={() => setShowPolicy(false)}
          />
          <PrivacyPolicy/>
        </div>
      </ModalPolicy>
      <ModalSuccess showModal={showModalSuccess}>
        <div className={style.wrapModalSuccess}>
          {content.chess.chessForm[lang].success}
          <img src={require('./images/smile.png')} width="58" height="58" alt='smile'/>
        </div>
      </ModalSuccess>
    </div>
    
  )
}
