import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ModalWrapper from '../../wrapers/ModalWrarrer/ModalWrapper'
import { content } from '../../constants/langs'
import { layoutsImgA, layoutsImgB, layoutsImgPlane, layoutsImgC, layoutsImgD, layoutsImg18A, layoutsImgE, layoutsImgF, layoutsImgG } from '../../constants/plans'

import style from './Layouts.module.scss'

export default function Layouts() {

  const mobile = useSelector(({changeTextReducer: { mobile }}) => mobile)
  const lang = useSelector(({changeTextReducer: { lang }}) => lang)
  const text = useSelector(({changeTextReducer: { text }}) => text)

  const [imgA, setImgA] = useState(0)
  const [imgB, setImgB] = useState(0)
  const [imgP, setImgP] = useState(0)
  const [imgC, setImgC] = useState(0)
  const [imgD, setImgD] = useState(0)
  const [img18A, setImg18A] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [showPlane, setShowPlane] = useState("A")

  const nextImg = (arg) => {
    if (arg === "A") setImgA(1)
    if (arg === "B") setImgB(1)
    if (arg === "P") setImgP(1)
    if (arg === "C") setImgC(1)
    if (arg === "D") setImgD(1)
    if (arg === "18A" && img18A < 3) setImg18A(img18A +1)
  }
  const prevImg = (arg) => {
    if (arg === "A") setImgA(0)
    if (arg === "B") setImgB(0)
    if (arg === "P") setImgP(0)
    if (arg === "C") setImgC(0)
    if (arg === "D") setImgD(0)
    if (arg === "18A" && img18A > 0) setImg18A(img18A -1)
  }

  const onShowModal = (arg) => {
    if (!mobile){
      setShowModal(true)
      setShowPlane(arg)
    }
  }

  const arrows = (type, modal) => {
    return <>
      <img src={require('./images/arrow_right.png')} style={{position: "absolute", 
        top: "40%", right:  modal === "3k" ? 48 : 0, cursor: "pointer"}} width="20" height="20" alt='search'
        onClick={() => nextImg(type)}
      />
      <img src={require('./images/arrow_left.png')} style={{position: "absolute", 
        top: "40%", left: 0, cursor: "pointer"}} width="20" height="20" alt='search'
        onClick={() => prevImg(type)}
      />
    </>
  }

  return (
    <div className={style.mainLayouts}>
      <span className={style.title}>{content.chess.title[lang].title}</span>
      <div className={style.wrapLayouts}>
      <div className={style.layouts}>
          <div className={style.wrapImg}
            onClick={() => onShowModal("P")}
          >
            {layoutsImgPlane(imgP)}
          </div>
          {arrows("P")}
          <span style={{fontSize: text ? 24 : 20}}>{imgP === 0 ? content.chess.plane[lang].textP : content.chess.plane[lang].textPP}</span>
        </div>
        <div className={style.layouts}>
          <div className={style.wrapImg}
            onClick={() => onShowModal("A")}
          >
            {layoutsImgA(imgA)}
          </div>
          {arrows("A")}
          <span style={{fontSize: text ? 24 : 20}}>{content.chess.plane[lang].textA}</span>
        </div>
        <div className={style.layouts}>
          <div className={style.wrapImg}
            onClick={() => onShowModal("B")}
          >
            {layoutsImgB(imgB)}
          </div>
          {arrows("B")}
          <span style={{fontSize: text ? 24 : 20}}>{content.chess.plane[lang].textB}</span>
        </div>
      </div>
      <div className={style.wrapLayouts}>
        <div className={style.layouts}>
          <div className={style.wrapImg}
            onClick={() => onShowModal("C")}
          >
            {layoutsImgC(imgC)}
          </div>
          {arrows("C")}
          <span style={{fontSize: text ? 24 : 20}}>{content.chess.plane[lang].textC}</span>
        </div>
        <div className={style.layouts}>
          <div className={style.wrapImg}
            onClick={() => onShowModal("D")}
          >
            {layoutsImgD(imgD)}
          </div>
          {arrows("D")}
          <span style={{fontSize: text ? 24 : 20}}>{content.chess.plane[lang].textD}</span>
        </div>
        <div className={style.layouts}>
          <div className={style.wrapImg}
            onClick={() => onShowModal("18A")}
          >
            {layoutsImg18A(img18A)}
          </div>
          {arrows("18A")}
          <span style={{fontSize: text ? 24 : 20}}>{content.chess.plane[lang].text18A}</span>
        </div>
      </div>
      <div className={style.wrapLayouts}>
        <div className={style.layouts}
          onClick={() => onShowModal("E")}
        >
          <div className={style.wrapImg}>
            {layoutsImgE()}
          </div>
          <span style={{fontSize: text ? 24 : 20}}>{content.chess.plane[lang].textE}</span>
        </div>
        <div className={style.layouts}
          onClick={() => onShowModal("F")}
        >
          <div className={style.wrapImg}>
            {layoutsImgF()}
          </div>
          <span style={{fontSize: text ? 24 : 20}}>{content.chess.plane[lang].textF}</span>
        </div>
        <div className={style.layouts}
          onClick={() => onShowModal("G")}
        >
          <div className={style.wrapImg}>
            {layoutsImgG()}
          </div>
          <span style={{fontSize: text ? 24 : 20}}>{content.chess.plane[lang].textG}</span>
        </div>
      </div>
      <ModalWrapper showModal={showModal}>
        <div style={{position: "relative", width: "94%"}}>
          <img src={require('./images/close.png')} style={{position: "absolute", right: showPlane === "A" || showPlane === "B" ? 4 : 48, top: -4 }} width="38" height="38" alt='close'
            onClick={() => setShowModal(false)}
          />
          {showPlane === "A" ? layoutsImgA(imgA) : showPlane === "B" ? layoutsImgB(imgB) : showPlane === "C" ? layoutsImgC(imgC) 
            : showPlane === "P" ? layoutsImgPlane(imgP) : showPlane === "D" ? layoutsImgD(imgD) : showPlane === "18A" ? layoutsImg18A(img18A) 
            : showPlane === "E" ? layoutsImgE() : showPlane === "F" ? layoutsImgF() : showPlane === "G" ? layoutsImgG() : ""
          }
          {showPlane === "A" ? arrows("A", "2k") : showPlane === "B" ? arrows("B", "2k") : showPlane === "P" ? arrows("P", "2k") : showPlane === "C" ? arrows("C", "3k")
            : showPlane === "D" ? arrows("D", "3k") : showPlane === "18A" ? arrows("18A", "3k") : ""
          }
        </div>
      </ModalWrapper>
    </div>
  )
}
