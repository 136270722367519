export const layoutsImgA = (arg) => {
    return <>
        <img src={require('./images/a.png')} style={{display: arg === 0 ? "" : "none"}} width="100%" height="auto" alt='layoutsA'/>
        <img src={require('./images/aa.png')} style={{display: arg === 1 ? "" : "none"}} width="100%" height="auto" alt='layoutsAA'/>
    </>
}
export const layoutsImgB = (arg) => {
    return <>
        <img src={require('./images/b.png')} style={{display: arg === 0 ? "" : "none"}} width="100%" height="auto"  alt='layoutsB'/>
        <img src={require('./images/bb.png')} style={{display: arg === 1 ? "" : "none"}} width="100%" height="auto" alt='layoutsBB'/>
    </>
}
export const layoutsImgPlane  = (arg) => {
    return <>
        <img src={require('./images/floor1.png')} style={{display: arg === 0 ? "" : "none"}} width="100%" height="auto" alt='layoutsP'/>
        <img src={require('./images/floor2-17.png')} style={{display: arg === 1 ? "" : "none"}} width="100%" height="auto" alt='layoutsPP'/>
    </>
}
export const layoutsImgC  = (arg) => {
    return <>
        <img src={require('./images/c.png')} style={{display: arg === 0 ? "" : "none"}} width="94%" height="auto" alt='layoutsC'/>
        <img src={require('./images/cc.png')} style={{display: arg === 1 ? "" : "none"}} width="94%" height="auto" alt='layoutsCC'/>
    </>
}
export const layoutsImgD  = (arg) => {
    return <>
        <img src={require('./images/d.png')} style={{display: arg === 0 ? "" : "none"}} width="94%" height="auto" alt='layoutsD'/>
        <img src={require('./images/dd.png')} style={{display: arg === 1 ? "" : "none"}} width="94%" height="auto" alt='layoutsDD'/>
    </>
}
export const layoutsImg18A  = (arg) => {
    return <>
        <img src={require('./images/18a.png')} style={{display: arg === 0 ? "" : "none"}} width="94%" height="auto" alt='layouts18A'/>
        <img src={require('./images/18aa.png')} style={{display: arg === 1 ? "" : "none"}} width="94%" height="auto" alt='layouts18AA'/>
        <img src={require('./images/18b.png')} style={{display: arg === 2 ? "" : "none"}} width="94%" height="auto" alt='layouts18B'/>
        <img src={require('./images/18bb.png')} style={{display: arg === 3 ? "" : "none"}} width="94%" height="auto" alt='layouts18BB'/>
    </>
}
export const layoutsImgE  = () => {
    return <>
        <img src={require('./images/e.png')} width="100%" height="auto" alt='layoutsE'/>
    </>
}
export const layoutsImgF  = () => {
    return <>
        <img src={require('./images/f.png')} width="100%" height="auto" alt='layoutsF'/>
    </>
}
export const layoutsImgG  = () => {
    return <>
        <img src={require('./images/g.png')} width="100%" height="auto" alt='layoutsG'/>
    </>
}