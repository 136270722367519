import { all } from 'redux-saga/effects'

import watcherGetChess from './getChessSaga'
import watcherSendData from './sendDataSaga'
import watcherGetNews from './getNewsSaga'
import watcherPostChess from './postChessSaga'

export default function* rootSaga() {
    yield all([
        watcherGetChess(),
        watcherSendData(),
        watcherGetNews(),
        watcherPostChess()
    ])
}