import React from 'react'
import { useSelector } from 'react-redux'
import { content } from '../../constants/langs'
import style from './PrivacyPolicy.module.scss'

export default function PrivacyPolicy() {

  const lang = useSelector(({changeTextReducer: { lang }}) => lang)
  const text = useSelector(({changeTextReducer: { text }}) => text)

  return (
    <div className={style.wrapPolicy} style={{fontSize: text ? 22 : 18}}>
      {content.privacyPolicy[lang].title}
      {content.privacyPolicy[lang].text}
    </div>
  )
}
