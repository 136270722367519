import { takeEvery, put, call } from 'redux-saga/effects'
import { GET_NEWS, getNewsSuccess } from '../actions/restChessActions'
import httpProvider from '../../common/httpProvider'
import { NEWS_URL } from '../../common/api'

function* workerLoader() {
  try {
    const { data } = yield call(httpProvider.get, NEWS_URL)
    yield put(getNewsSuccess(data))
  } catch (error) {
    yield put(console.error(error))
  }
}

export default function* watcherGetNews() {
  yield takeEvery(GET_NEWS, workerLoader)
}