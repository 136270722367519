const initialState = {
    lang: "ru",
    text: false,
    mobile: false,
    module: "Шахматка"
}

const changeTextReducer = (state = initialState, action) => {
    switch (action.type){ 
        case 'CHANGE_LANG':
            return {
                ...state,
                lang: action.payload
            }
        case 'SET_TEXT':
        return {
            ...state,
            text: action.payload
        }
        case 'IS_MOBILE':
        return {
            ...state,
            mobile: action.payload
        }
        case 'SELECTED_MODULE':
        return {
            ...state,
            module: action.payload
        }
        default: 
        return state;  
    }
}

export default changeTextReducer