export const getChess = (data) => {
    return {
        type: 'GET_CHESS',
        payload: data 
    } 
}
export const GET_CHESS = 'GET_CHESS'

export const getChessSuccess = (data) => {
    return {
        type: 'GET_CHESS_SUCCESS',
        payload: data 
    } 
}
export const getChessError = (data) => {
    return {
        type: 'GET_CHESS_ERROR',
        payload: data 
    } 
}
export const postChess = (data) => {
    return {
        type: 'POST_CHESS',
        payload: data 
    } 
}
export const POST_CHESS = 'POST_CHESS'
export const postChessSuccess = (data) => {
    return {
        type: 'POST_CHESS_SUCCESS',
        payload: data 
    } 
}
export const sendData = (data) => {
    return {
        type: 'SEND_DATA',
        payload: data 
    } 
}
export const SEND_DATA = 'SEND_DATA'

export const sendDataSuccess = (data) => {
    return {
        type: 'SEND_DATA_SUCCESS',
        payload: data 
    } 
}
export const sendDataError = (data) => {
    return {
        type: 'SEND_DATA_ERROR',
        payload: data 
    } 
}

export const changeMessageStatus = () => {
    return {
        type: 'CHANGE_MESSAGE_STATUS'
    } 
}
export const chooseFlat = (data) => {
    return {
        type: 'CHOOSE_FLAT',
        payload: data 
    } 
}
export const getNews = (data) => {
    return {
        type: 'GET_NEWS',
        payload: data 
    } 
}
export const GET_NEWS = 'GET_NEWS'
export const getNewsSuccess = (data) => {
    return {
        type: 'GET_NEWS_SUCCESS',
        payload: data 
    } 
}

