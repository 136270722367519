import React, {useState, useEffect ,useLayoutEffect} from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { content } from '../../constants/langs'
import { changeLang, setText } from '../../core/actions/changeTextAction'

import style from './Header.module.scss'

export default function Header() {
  const dispatch = useDispatch()

  const [mobile, setMobile] = useState(false)
  const [menu, setMenu] = useState(true)
  const [showLang, setShowLang] = useState(false)
  const [lang, setlang] = useState("ru")
  const [riseText, setRiseText] = useState(false)
  const [showTelegram, setShowTelegram] = useState(false)

  useLayoutEffect(() => {
    const userAgent = navigator.userAgent
    const isMobile = /mobile|iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent)
    setMobile(isMobile)
  }, [])

  useEffect(() => {
    dispatch(changeLang(lang))
  }, [lang])
  
  useEffect(() => {
    dispatch(setText(riseText))
  }, [riseText])

  return (
    <div className={style.header} style={{height: mobile ? 58 : 80}}>
      {
        mobile ? <div className={menu ? style.menu : style.close}
        onClick={() => setMenu(item => !item)}/> : ''
      }
      <img src={require('./images/logo2.png')} rel="preconnect" style={{display: mobile ? "none" : "", marginLeft: 38, width: 79, height: 80}}alt='eye'/>
      <div className={style.wrapLang}>
        <div className={style.lang}
          onClick={() => setShowLang(item => !item)}
        >
          <img src={require('./images/translateF.png')} style={{width:46, height: 44, marginTop: 6}} alt='lang'/>
        </div>
        <div className={style.showLang} style={{display: showLang ? "flex" : "none"}}>
          <span
            style={{textDecoration: lang === "ru" ? "underline" : ""}}
            onClick={() => setlang("ru")}
          >ru</span>
          <span
            style={{textDecoration: lang === "br" ? "underline" : ""}}
            onClick={() => setlang("br")}
          >br</span>
          <span
            style={{textDecoration: lang === "en" ? "underline" : ""}}
            onClick={() => setlang("en")}
          >en</span>
        </div>
        <img src={require('./images/visibilityF.png')} style={{marginTop: 8, marginLeft: 18, width: 46, height: 46}}alt='eye'
          onClick={() => setRiseText(item => !item)}
        />
      </div>
      <div className={style.wrapLinks} 
        style={{display: mobile && menu ? "none" : mobile && !menu ? "flex" : !mobile && menu ? "flex" : ""}}
      >
          <a href="#О_комплексе" style={{textDecoration: 'none'}}>
            <span className={style.links} style={{fontSize: riseText ? 20 : 15}}>{content.header[lang].aboutComplex}</span>
          </a>
          <Link className={style.wrapChooseFlat} to="/chess" style={{textDecoration: "none"}}>
            <span className={style.links} style={{fontSize: riseText ? 20 : 15}}>{content.header[lang].chooseApartment}</span>
          </Link>
          <a href="#Условия_оплаты" style={{textDecoration: 'none'}}>
            <span className={style.links} style={{fontSize: riseText ? 20 : 15}}>{content.header[lang].paymentTerms}</span>
          </a>
          <a href="#Контакты" style={{textDecoration: 'none'}}>
            <span className={style.links} style={{fontSize: riseText ? 20 : 15}}>{content.header[lang].contact}</span>
          </a>
      </div>
      <div className={style.wrapMessenger}>
        <img src={require('./images/forumF.png')} style={{width: 46, height: 46, paddingTop: 8}} alt='forum'
          onClick={() => setShowTelegram(item => !item)}
        />
        <a title="Telegram" href="tg://resolve?phone=+375291951821" aria-label="telegram">
          <img src={require('./images/telegram.png')} 
            style={{display: showTelegram ? "" : "none", width: mobile? 34 : 40, height: mobile? 34 : 40, position: "absolute", top: mobile ? 60 : 72, right: 34}} alt='forum' 
          />
        </a> 
      </div>
    </div>
  )
}
