import { takeEvery, put, call, select } from 'redux-saga/effects'
import { POST_CHESS, postChessSuccess } from '../actions/restChessActions'
import httpProvider from '../../common/httpProvider'
import { POST_CHESS_URL } from '../../common/api'

function* workerLoader() {
    try {
        const { data } = yield call(httpProvider.post, POST_CHESS_URL, {data: {data: {chess: "chess"}}})
        yield put(postChessSuccess(data))
      } catch (error) {
        yield put(console.log(error))
      }
  }

export default function* watcherPostChess() {
  yield takeEvery(POST_CHESS, workerLoader)
}