import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Popover } from 'antd'

import ModalWrapper from '../../wrapers/ModalWrarrer/ModalWrapper'
import { content } from '../../constants/langs'
import { chooseFlat } from '../../core/actions/restChessActions'
import { layoutsImgPlane } from '../../constants/plans'
import style from './Interactive.module.scss'

export default function Interactive() {
  const dispatch = useDispatch()

  const chess = useSelector(({restChessReducer: { chess }}) => chess)
  const lang = useSelector(({changeTextReducer: { lang }}) => lang)
  const mobile = useSelector(({changeTextReducer: { mobile }}) => mobile)

  const [showModal, setShowModal] = useState(false)
  const [floor, setFloor] = useState(1)
  const [isHover, setIsHover] = useState(false)

  const onShowModal = (inx) => {
    setShowModal(true)
    setFloor(inx)
  }

  const imgStyles = ['', style.floor1, style.floor2, style.floor3, style.floor4, style.floor5, style.floor6, style.floor7, style.floor8, style.floor9,
    style.floor10, style.floor11, style.floor12, style.floor13, style.floor14, style.floor15, style.floor16, style.floor17, style.floor18
  ]
  
  const freeFlats = chess?.filter(item => item.status === "Свободно")
  const freeFlats2 = freeFlats?.filter(item => item.amountRooms == 2)
  const freeFlats3 = freeFlats?.filter(item => item.amountRooms == 3)
  const freeFlats5 = freeFlats?.filter(item => item.amountRooms == 5)

  return (
    <div className={style.wrapInteractive}>
      {
        mobile ? <img className={style.image} src={require('./images/main3.png')}  alt='main1'/>
        : <img className={style.image} src={require('./images/main.webp')}  alt='main'/>
      }
      <div className={style.home1Content} style={{display: isHover ? "flex" : "none"}}>
        <span className={style.title}>Дом №1</span>  
        <span className={style.text}>Доступно помещений:&nbsp; {freeFlats?.length}</span>
        <span className={style.text} style={{marginLeft: 20}}>2-х комнатных - {freeFlats2?.length}</span>
        <span className={style.text} style={{marginLeft: 20}}>3-х комнатных - {freeFlats3?.length}</span>
        <span className={style.text} style={{marginLeft: 20}}>5-ти комнатных - {freeFlats5?.length}</span>
      </div>
      {
        imgStyles.map((item, index) => {return(
          <div className={item}
            onClick={() => onShowModal(index)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          />
        )})
      }
      <ModalWrapper showModal={showModal}>
        <div style={{position: "relative", width: "90%"}}>
          <span className={style.floorModal}>{floor} {content.chess.interactive[lang].floor}</span>
          <img src={require('./images/close.png')} style={{position: "absolute", right: 8, top: 4 }} width="38" height="38" alt='close'
            onClick={() => setShowModal(false)}
          />
          {floor === 1 
            ? <div className={style.wrapPlane}>
                {layoutsImgPlane(0)}
                <div className={style.typeE}
                  onClick={() => dispatch(chooseFlat({floor, type: "E"}))}
                />
                <div className={style.typeF}
                  onClick={() => dispatch(chooseFlat({floor, type: "F"}))}
                />
                <div className={style.typeG}
                  onClick={() => dispatch(chooseFlat({floor, type: "G"}))}
                />
              </div> 
            : <div className={style.wrapPlane}>
              {layoutsImgPlane(1)}
              <div className={style.typeA}
                onClick={() => dispatch(chooseFlat({floor, type: "A"}))}
              />
              <div className={style.typeB}
                onClick={() => dispatch(chooseFlat({floor, type: "B"}))}
              />
              <div className={style.typeC}
                onClick={() => dispatch(chooseFlat({floor, type: "C"}))}
              />
              <div className={style.typeD}
                onClick={() => dispatch(chooseFlat({floor, type: floor === 18 ? "18A" : "D"}))}
              />
            </div>
          }
          <span className={style.textModal}>{content.chess.interactive[lang].text}</span>
        </div>
      </ModalWrapper>
    </div>
  )
}
