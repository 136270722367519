export const changeLang = (data) => {
    return {
        type: 'CHANGE_LANG',
        payload: data 
    } 
}

export const setText = (data) => {
    return {
        type: 'SET_TEXT',
        payload: data 
    } 
}
export const isMobile = (data) => {
    return {
        type: 'IS_MOBILE',
        payload: data 
    } 
}
export const selectedModule = (data) => {
    return {
        type: 'SELECTED_MODULE',
        payload: data
    } 
}