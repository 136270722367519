const initialState = {
    chess: null,
    data: "",
    flat: null,
    successMessage: null,
    news: null
}

const restChessReducer = (state = initialState, action) => {
    switch (action.type){ 
        case 'GET_CHESS_SUCCESS':
            return {
                ...state,
                chess: action.payload
            }
        case 'POST_CHESS_SUCCESS':
            return {
                ...state
            }
        case 'SEND_DATA':
            return {
                ...state,
                data: action.payload
            }
        case 'SEND_DATA_SUCCESS':
            return {
                ...state,
                successMessage: action.payload
            }
        case 'CHANGE_MESSAGE_STATUS':
        return {
            ...state,
            successMessage: null
        }
        case 'CHOOSE_FLAT':
        return {
            ...state,
            flat: action.payload
        }
        case 'GET_NEWS_SUCCESS':
        return {
            ...state,
            news : action.payload
        }
        default: 
        return state;  
    }
}

export default restChessReducer

